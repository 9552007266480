<template>
  <VLotPartialList class="mb-20">
    <VLotPartialItemDefault
      class="info-base__left"
      class-grid="grid--col-2"
    >
      <template #title>Основная информация</template>
      <template #column>
        <VTextBlock
          class="overflow-none svg-popup"
          subtitle="Режим проведения ТП"
          :description="lot.procedureType?.name"
        >
          <template #popupHead>
            <v-popup v-model="isOpenPopup">
              <template #dialog>
                <b>{{ procedureDescription.type }}</b>
                <hr>

                {{ procedureDescription.condition }}
                <p class="mt-10">
                  {{ procedureDescription.text }}
                </p>
              </template>
            </v-popup>
          </template>
        </VTextBlock>
        <VTextBlock
          subtitle="Время автоматич. продления ТП"
          :description="lot.extension_time?.slice(0, 5)"
        />
        <VTextBlock
          subtitle="Длительность ТП"
          :description="getDurability"
        />

        <VTextBlock
          subtitle="Тип лота"
          :description="!lot.divisibility ? 'Делимый' : 'Не делимый'"
        />

        <VTextBlock
          subtitle="Допустимость аналогов"
          :description="lot.analog_acceptable ? 'Да' : 'Нет'"
        />

        <VTextBlock
          subtitle="№ заказа поставщика"
          :description="lot?.order_number"
        />
      </template>
    </VLotPartialItemDefault>
    <VLotPartialItemDefault
      class="info-base__right"
      class-grid="grid--col-2"
    >
      <template #title>Об организаторе</template>
      <template #column>
        <VTextBlock
          subtitle="ФИО ответственного за лот"
          :description="lot?.responsible_full_name"
        />
        <VTextBlock
          subtitle="Тел. ответственного"
          :description="lot?.responsible_phone"
          is-tel
        />

        <VTextBlock
          subtitle="Компания"
          :description="lot.company?.name"
        />

        <VTextBlock
          v-if="lot.phone_extension"
          subtitle="Добавочный номер телефона"
          :description="lot.phone_extension"
        />

        <VTextBlock
          subtitle="Адрес электронной почты"
          :description="lot?.user?.email"
          is-email
        />

        <VTextBlock
          subtitle="Примечание"
          :description="lot.note"
        />

        <VSelect
          v-if="isBidderActive && !isOrganizer"
          v-model="formData.relation_to_products_id"
          name="relation_to_products_id"
          id="relation_to_products_id"
          classes="select-border-bottom"
          :options="items"
          :errors="v$.relation_to_products_id.$errors"
          :server-errors="serverErrors.value?.relation_to_products_id"
          @input="validateField"
          @blur="validateField"
          class="input-long mb-0 form__control--width-450"
          placeholder="Отношение к продукции"
        />

      </template>
    </VLotPartialItemDefault>
  </VLotPartialList>

  <VLotPartialList class="info-base-limit-form">
    <div class="flex">
      <VLotPartialItemDefault
        class-grid="mt-30"
      >
        <template #title>
          <span>Условия поставки</span>
        </template>

        <template #column>
          <VLotParametersDeliveryConditions
            :is-bidder="isBidder"
            :is-edit="isEditDelivery && isBidderActive"
            is-split
          />
        </template>
      </VLotPartialItemDefault>
      <VLotPartialItemDefault
        class-grid="mt-30"
        class="pl-0"
      >
        <template #title>
          Условия оплаты
        </template>

        <template #column>
          <VLotParametersPaymentConditions
            is-split
            :is-bidder="isBidder"
            :minCriterionDisabled="true"
            :maxCriterionDisabled="true"
            :is-edit="isEditPayment && isBidderActive"
          />
        </template>
      </VLotPartialItemDefault>
    </div>

    <div class="ml-20 p-30 bg--light-gray documents">
      <h3 class="info-base__title mb-30">Сопроводительная документация</h3>

      <div class="documents__block">
        <VLotParametersFile
          :is-bidder="isBidder"
          :is-create="false"
        />
      </div>
    </div>
  </VLotPartialList>

  <h3 class="block__subtitle mb-40 mt-60">
    Позиции
  </h3>

  <VTableCreateTrade
    v-if="formData.positions?.length"
    :positions="formData.positions"
    :key="keyTable"
    :is-scroll="false"
    :is-bidder-active="isBidderActive"
    :is-organizer="isOrganizer"
  />

  <div class="documents__block" v-if="isBidderActive">
    <h3 class="info-base__title mb-40">Документация для участия</h3>
    <VLotParametersFile
      :isShowList="false"
      :is-bidder="isBidder"
      :is-create="isBidderActive && !isOrganizer"
    />
  </div>

  <div v-if="$route.meta.slug === 'organizer'">
    <h3 class="block__subtitle mb-40 mt-60">
      История ставок
    </h3>

    <slot name="history" />
  </div>

</template>

<script>
import { computed, onBeforeMount, onMounted, reactive, ref, watchEffect } from 'vue'
import { lotInfoProps } from '@/mixin/props'
import VTextBlock from '@/components/textblock/VTextBlock'
import { calculateRangeBetweenDate } from '@/utils/utils'
import VPopup from '@/modules/popup/VPopup'
import descriptionProcedure from '@/core/descriptionProcedure'
import VLotPartialList from '@/components/lot-partial/VLotPartialList'
import VLotPartialItemDefault from '@/components/lot-partial/VLotPartialItemDefault'
import VSelect from '@/components/ui/form/VSelect'
import VLotParametersPaymentConditions from '@/components/views/Lot/Create/Part/VLotParametersPaymentConditions'
import VLotParametersDeliveryConditions from '@/components/views/Lot/Create/Part/VLotParametersDeliveryConditions'
import VLotParametersFile from '@/components/views/Lot/Create/Part/VLotParametersFile'
import VTableCreateTrade from '@/components/views/VTableCreateTrade'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { RELATION } from '@/utils/manual/manualList'

export default {
  mixins: [lotInfoProps],
  setup (props) {
    const items = reactive([])
    const isOpenPopup = ref(false)

    onMounted(() => {
      isOpenPopup.value = true
      setTimeout(() => {
        isOpenPopup.value = false
      }, 5000)
    })

    onBeforeMount(async () => {
      items.push(...await manualMemoization(true, RELATION))
    })

    const keyTable = ref('KEY_TABLE')

    watchEffect(() => {
      keyTable.value += props.isBidderActive
    })

    const procedureDescription = ref(descriptionProcedure[props.lot.procedureType.id])

    const getDurability = computed(() => {
      return calculateRangeBetweenDate(props.lot.started_at, props.lot.duration)
    })

    return {
      keyTable,
      procedureDescription,
      getDurability,
      items,
      isOpenPopup
    }
  },
  components: {
    VSelect,
    VLotPartialItemDefault,
    VLotPartialList,
    VPopup,
    VTextBlock,
    VLotParametersDeliveryConditions,
    VLotParametersPaymentConditions,
    VLotParametersFile,
    VTableCreateTrade
  }
}
</script>

<style lang="scss">
.svg-popup {
  & svg {
    margin-left: 10px;
  }
}

.pl-0 {
  padding-left: 0;
}

.documents {
  min-width: 470px;

  &__block {
    height: 85%;
    overflow: auto;
  }

  & .files--grid {
    display: flex;
  }
}
</style>
